.wrapper {
    margin-top: 15.2vh;
    height: 84.8vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.first {
    font-size: 20rem;
    font-weight: 600;
    color: var(--primitive-text-color);
    line-height: 1;
    display: flex;
    align-items: center;
}

.first-image {
    filter: grayscale(100%);
    height: 41vh;
    margin-left: 6vh;
    margin-right: 6vh;
}

.middle {
    font-size: 3rem;
    text-align: center;
}

.last {
    background-color: var(--primitive-color);
    color: white;
    width: 37vh;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    font-weight: 700;
}
