.wrapper {
    margin-top: 15.2vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.form-wrapper {
    margin-right: 9vh;
    width: 85.37vh;
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    flex-direction: column;
}

.a-line-field {
    display: flex;
}

.field-label {
    font-weight: 700;
    color: var(--primitive-text-color);
    margin-left: 1.85vh;
}

.hoathi {
    color: red;
}

.field-input {
    border: 1.5px solid var(--primitive-color);
    border-radius: 1.5vh;
    height: 8.15vh;
    font-size: 2.5rem;
    padding: 1vh;
    font-family: var(--primitive-font-family);
}

.username-input {
    width: 41.66vh;
}

.fullname-input {
    width: 41.66vh;
    margin-left: 2.22vh;
}

.day-input {
    width: 27vh;
}

.month-input,
.year-input {
    width: 27vh;
    margin-left: 2.59vh;
}

.validate-info {
    color: red;
    font-size: 1.5rem;
    margin-left: 1.85vh;
}

.register-button {
    width: 85.37vh;
    height: 8.33vh;
    background-color: var(--primitive-color);
    color: white;
    font-weight: 700;
    font-size: 2.5rem;
    margin-top: 3vh;
    border-radius: 1.5vh;
}

.logo {
    width: 37.5vw;
    height: 37.5vw;
}

.user {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    width: 40.74vh;
    height: 40.74vh;
}

.title {
    margin-top: 5.56vh;
    margin-bottom: 4.35vh;
    color: var(--primitive-color);
}

.fullname-confirm {
    margin-top: 3.8vh;
    font-size: 2.6rem;
}

.username-confirm {
    font-size: 1.7rem;
}

.info-confirm {
    color: var(--primitive-text-color);
}

.security-question-label {
    margin-top: 7.3vh;
}

.confirm-button {
    width: 34.81vh;
    height: 6.3vh;
    background-color: #5dc277;
    color: white;
    margin-top: 3.7vh;
    align-self: flex-end;
    font-weight: 700;
    font-size: 2.5rem;
    margin-top: 3vh;
    border-radius: 999px;
}

.confirm-wrapper {
    width: 76.67vh;
    height: 58.15vh;
    padding-left: 12.5vh;
    padding-right: 12.5vh;
    background-color: white;
    box-shadow: 3px 3px 3px 3px #9c9993;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 7.4vh;
}

.confirm {
    width: 100%;
    height: 100%;
}

.check-icon {
    width: 13.15vh;
    height: 10vh;
}

.confirm-text {
    color: var(--primitive-text-color);
}

.confirm-button-popup {
    background-color: #5dc277;
    color: white;
    border-radius: 999px;
    width: 34.81vh;
    height: 6.11vh;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 0;
}

.popupOpen {
    opacity: 30%;
    background-color: #fffbf1;
}

.remember-login {
    color: var(--primitive-text-color);
    margin-left: 1.67vh;
}

.login-field {
    margin-bottom: 2.7vh;
}

.checkbox-login-wrapper {
    display: flex;
    justify-content: space-between;
}

.forget-password {
    color: var(--primitive-color);
}

.link {
    color: var(--primitive-text-color);
    text-decoration: underline;
}

.alert-login {
    font-size: 2rem;
    color: var(--primitive-color);
    display: flex;
    flex-direction: center;
    align-items: center;
    gap: 20px;
    padding: 1rem;
    border: 1px solid var(--primitive-color);
    border-radius: 10px;
    margin-bottom: 30px;
}

.alert-login a {
    font-weight: bold;
}