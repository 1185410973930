.field {
    display: flex;
    flex-direction: column;
}

.field-label {
    font-weight: 700;
    color: var(--primitive-text-color);
    margin-left: 1.85vh;
}

.field-input {
    border: 1.5px solid var(--primitive-color);
    border-radius: 1.5vh;
    height: 8.15vh;
    font-size: 2.5rem;
    padding: 1vh;
    font-family: var(--primitive-font-family);
}

.checkbox-login-wrapper {
    display: flex;
    justify-content: space-between;
}

.eyes {
    width: 50px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.hoathi {
    color: red;
}
