.btn-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.btn {
    width: 35vh;
    height: 7vh;
    border-radius: 999px;
    border: 2px solid var(--primitive-color);
    color: var(--primitive-color);
    font-size: 2rem;
    font-weight: 700;
    background-color: white;
}

.active {
    color: white;
    background-color: var(--primitive-color);
}
