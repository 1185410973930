.level-wrapper {
    display: flex;
    justify-content: space-evenly;
}

.captain-title-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 7vh;
}

.captain-title {
    text-align: center;
    color: white;
    background-color: var(--primitive-color);
    font-weight: 700;
    font-size: 1.5rem;
    width: 70%;
    border-top-left-radius: 999px;
    border-top-right-radius: 999px;
    align-self: center;
}

.captain-image {
    height: 12.5vh;
    margin-right: 1.67vh;
}

.member-image {
    height: 10vh;
    margin-right: 1.67vh;
}

.member-name {
    font-weight: 700;
    color: var(--primitive-color);
    font-size: 2.5rem;
}

.member-title {
    font-weight: 700;
    font-style: italic;
    font-size: 1.5rem;
}

.title {
    color: var(--primitive-color);
    font-weight: 700;
    text-align: center;
    font-size: 3rem;
    margin-top: 3.5vh;
    margin-bottom: 3.5vh;
}

.fieldset {
    margin: 5vh auto;
    border: 3px solid var(--primitive-color);
    border-radius: 40px;
    width: 157vh;
}

.member-wrapper-title {
    color: white;
    background-color: var(--primitive-color);
    font-weight: 700;
    text-align: center;
    border-radius: 999px;
    padding-left: 10vh;
    padding-right: 10vh;
}

.personnel-wrapper {
    display: flex;
    align-items: center;
    border: 2px solid var(--primitive-color);
    border-radius: 10px;
    padding: 5px 16px 5px 5px;
}

.captain-personnel-wrapper {
    margin-right: 4.16vh;
    max-width: 50vh;

    &:first-child {
        margin-left: 6.25vh;
    }

    &:last-child {
        margin-right: 6.25vh;
    }
}

.toEqually {
    flex: 1;
}

.captain-content-wrapper {
    width: 60vh;
}

.btn-wrapper {
    width: 100vw;
    display: flex;
    height: 22.22vh;
    background-color: #f2f2f2;
    margin-top: 3.5vh;
}

.btn-item {
    color: #afabab;
    font-weight: 700;
    font-size: 2.5rem;
    flex: 1;
}

.active {
    background-color: #d9d9d9;
    color: white;
}

.list-mem-branch-wrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    flex-wrap: wrap;
}

.member-branch-wrapper {
    min-width: 50vh;
    margin-bottom: 2vh;

    &:nth-child(3n + 1) {
        margin-left: 2vh;
    }

    &:nth-child(3n + 0) {
        margin-right: 2vh;
    }

    &:nth-child(3n + 1) {
        margin-right: 1vh;
    }

    &:nth-child(3n + 2) {
        margin-right: 1vh;
    }
}

.final-wrapper {
    margin-bottom: 5vh;
}
