@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@font-face {
    font-family: UVNThanhPho;
    src: url('../../assets/fonts/unicode.display.UVNThanhPho_B.TTF');
}

// :root {
//     --primitive-font-family: Work Sans;
//     --primitive-color: #3a7e57;
//     --primitive-text-color: #767171;
//     --correct: lawngreen;
//     --wrong: red;
//     --green-active: limegreen;
// }

:root {
    --primitive-font-family: Work Sans;
    --primitive-color: #543d0f;
    --primitive-text-color: #767171;
    --correct: lawngreen;
    --wrong: red;
    --green-active: limegreen;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    overflow-y: overlay;
}

body {
    font-family: var(--primitive-font-family);
    font-size: 2.2rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
}

*::-webkit-scrollbar {
    display: block;
    width: 10px;
}

*::-webkit-scrollbar-button {
    display: none;
}

*::-webkit-scrollbar-track {
    background-color: #00000000;
}

*::-webkit-scrollbar-track-piece {
    background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
    background-color: #00000040;
    border: 0px solid transparent;
    border-radius: 24px;
    box-shadow: none;
}

button,
input,
textarea,
[tabIndex] {
    outline: none;
    border: none;
    font-family: var(--primitive-font-family);
}

a {
    text-decoration: none;
}

button:hover,
a:hover {
    cursor: pointer;
}

.correct-color {
    background-color: var(--correct);
    color: white;
}

.wrong-color {
    background-color: var(--wrong);
    color: white;
}

.popupOpen {
    opacity: 50%;
}

.popup-wrapper {
    width: 35vw;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--primitive-color);
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.popup-btn {
    width: 70px;
    height: 30px;
    font-size: 2rem;
    margin: 8px;
}

.popup-btn:disabled {
    opacity: 50%;
}

.close {
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 2.4rem;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.save-successfully {
    background-color: rgb(51, 51, 51);
    color: white;
    font-size: 1.5rem;
    padding: 8px;
    border-radius: 5px;
    margin-top: 20px;
}

.ql-container.ql-disabled .ql-tooltip {
    display: none;
}

.ql-editor {
    font-family: var(--primitive-font-family);
}

a.link {
    padding: 1.76vh;
    border-radius: 999px;
    color: #afabab;

    &.active {
        padding-top: 1.2vh;
        padding-bottom: 1.2vh;
        background-color: #54ae7b;
        color: white;
    }
}
