.wrapper {
    display: flex;
}

.header-wrapper {
    margin-left: 10vh;
    margin-right: 11vh;
    width: 43vh;
}

.header {
    font-weight: 600;
}

.btn {
    color: white;
    background-color: var(--primitive-color);
    width: 50px;
    height: 30px;
    font-size: 2rem;
}

.input-btn {
    font-size: 2rem;
    padding-left: 5px;
    padding-right: 5px;
    width: 50px;
    height: 30px;
}

.content-wrapper {
    width: 110vh;
    display: flex;
    flex-direction: column;
}

.cell-label {
    font-weight: 700;
    color: var(--primitive-text-color);
    margin-left: 1.85vh;
    font-size: 2rem;
}

.cell-input {
    padding: 1vh;
    width: 90vh;
    border: 2px solid var(--primitive-color);
    border-radius: 10px;
    padding-left: 4px;
    font-size: 2rem;
    color: var(--primitive-text-color);
}

.media-btn {
    width: 20px;
    height: 20px;
    color: blue;
    background-color: white;
}

.explain-btn {
    width: 15px;
    height: 15px;
    color: blue;
    background-color: white;
}

.explain-wrapper {
    width: 40vw;
    height: 40vh;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--primitive-color);
    border-radius: 10px;
    color: white;
}

.audio-wrapper {
    background-color: white;
    color: var(--primitive-text-color);
    border: 4px solid var(--primitive-color);
}

.question-image {
    height: 30vh;
}

.pending {
    color: var(--primitive-text-color);
}

.notApprove {
    color: red;
}

.approve {
    color: lime;
}

.remove-favourite {
    font-size: 2rem;
    background-color: rgb(131, 9, 9);
    color: white;
    width: fit-content;
    padding: 1.5rem 2rem 1.5rem 2rem;
    border-radius: 5px;
    margin-top: 1rem;
}

.search-bar {
    font-size: 2rem;
    color: var(--primitive-color);
    display: flex;
    flex-direction: center;
    align-items: center;
    gap: 20px;
    padding: 1rem;
    border: 1px solid var(--primitive-color);
    border-radius: 10px;
    margin-bottom: 30px;
}

.search-bar input {
    font-size: 2rem;
    color: var(--primitive-color);
}

.favourite-noti {
    color: var(--primitive-color);
}

.alert-login a {
    font-weight: bold;
}