.wrapper {
    margin-top: 15.2vh;
}

.third-wrapper {
    height: 84.8vh;
}

.subject {
    font-size: 9rem;
    color: #3a7e57;
    margin-left: 10vw;
}

.predicate {
    margin-left: 10vw;
    width: 50%;
    font-size: 2rem;
    color: var(--primitive-text-color);
    margin-bottom: 5.56vh;
}

.contact-wrapper {
    display: flex;
    width: 78.125vw;
    height: 34vh;
    background-color: #f2f2f2;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 10vw;
    border-radius: 30px;
}

.contact-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-item {
    color: var(--primitive-text-color);
}

.email {
    font-size: 1.5rem;
}

.logo {
    height: 20vh;
}
